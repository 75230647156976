<template>
    <div class="index">
      <div class="introduce">
        <div class="avatarurl">
          <img src="https://lanzhiyuan99.cn/image/chuyin2.jpeg" />
        </div>
        <div class="username">我是爱吃土豆的挖掘机</div>
        <div class="introduce-item">一名热爱技术的90后</div>
        <div class="introduce-item">爱好不良</div>
        <div class="introduce-item">现于北京某科技公司担任前端开发</div>
      </div>
      <div class="skill">
        <div class="title">职业技能</div>
        <div class="skill-list">
          <vue-word-cloud
            :words="defaultWords"
            :color="getColor"
            font-family="Roboto"
          />
        </div>
      </div>
    </div>
</template>

<script>
  import VueWordCloud from 'vuewordcloud'
  export default {
    name: "index",
    data () {
      return {
        myColors: ['#ffd077', '#31a50d', '#d1b022', '#74482a' ,'#403030' , '#f97a7a' , '#d99cd1' ,'#3bc4c7', '#3a9eea', '#ff4e69', '#461e47'],
        defaultWords : [
          ['html',3],
          ['css',3],
          ['js',5],
          ['es6',5],
          ['webpack',5],
          ['vue',7],
          ['react',7],
          ['小程序',8],
          ['react-native',9],
          ['jquery',5],
          ['node',10],
          ['mysql',10],
          ['redis',10],
          ['linux',12],
          ['nginx',12],
        ]
      }
    },
    methods : {
      getColor () {
        let len = this.myColors.length
        let i = Math.floor(Math.random() * (0 - len)) + len;
        return this.myColors[i]
      }
    },
    components : {
      [VueWordCloud.name]: VueWordCloud,
    }
  }
</script>

<style scoped lang="scss">
  .introduce {
    background: rgb(250, 250, 250);
    padding: 40px ;
    text-align: center;
    font-weight: lighter;
    .username {
      font-size: 24px;
      margin-top: 10px;
    }
    .introduce-item {
      color: rgba(0,0,0,.57);
      margin-top: 30px;
    }
  }
  .skill {
    padding: 40px;
    text-align: center;
    font-weight: lighter;
    .title {
      font-size: 24px;
      margin-top: 10px;
    }
    .skill-list {
      width: 100%;
      height: 200px;
      margin: 40px auto;
    }
  }
  .avatarurl {
    display: flex;
    justify-content: center;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
</style>
